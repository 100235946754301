import type { FC } from 'react'
import { memo } from 'react'

import useSwitchTheme from '@hooks/theme/useSwitchTheme/useSwitchTheme'

import { ThemeSwitcherWrapper } from './styles'

const ThemeSwitcher: FC = () => {
  const { icon, switchThemeHandler, themeLabel } = useSwitchTheme()

  return (
    <ThemeSwitcherWrapper onClick={switchThemeHandler}>
      {icon}
      {themeLabel}
    </ThemeSwitcherWrapper>
  )
}

export default memo(ThemeSwitcher)
