import { useContext, useMemo } from 'react'

import { selectUserCurrency } from '@redux/features/user/user.selectors'
import { useAppSelector } from '@redux/hooks'
import { useGetCurrenciesQuery } from '@reduxCommonApp/commonApp.api'

import ServerSidePropsContext from '@pages/next/App/context/serverSidePropsContext'

export default () => {
  const serverSideProps = useContext(ServerSidePropsContext)
  const { data: currenciesList } = useGetCurrenciesQuery(true)
  const currentCurrency = useAppSelector(selectUserCurrency) || serverSideProps.currency

  return useMemo(
    () => (currentCurrency && currenciesList && currenciesList[currentCurrency]) || undefined,
    [currenciesList, currentCurrency]
  )
}
