import { paths } from '@constants/routes/routes'
import useIsCurrentPage from '@hooks/useIsCurrentPage'
import useOpenAuthPopup from '@hooks/useOpenAuthPopup/useOpenAuthPopup'

const useIsHideButtonTickets = () => {
  const isTimeTable = useIsCurrentPage(paths.timetable)
  const { isLoggedIn } = useOpenAuthPopup()
  return isTimeTable && !isLoggedIn
}

export default useIsHideButtonTickets
