class Cookie {
  eraseCookie(name: string) {
    if (typeof document === 'undefined') return null
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }

  getCookie(name: string) {
    if (typeof document === 'undefined') return null
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  setCookie(name: string, value: string, seconds: number) {
    if (typeof document === 'undefined') return null
    let expires = ''

    if (seconds) {
      const date = new Date()
      date.setTime(date.getTime() + seconds * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }
}

export const cookie = new Cookie()
