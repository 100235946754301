import { setIsOpenAuthorizationPopup } from '@redux/features/authorization/authorization.slice'
import { useAppDispatch } from '@redux/hooks'

import useIsLoggedIn from '@hooks/auth/useIsLoggedIn'

const useOpenAuthPopup = () => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useIsLoggedIn()

  const openAuthPopup = () => dispatch(setIsOpenAuthorizationPopup(true))

  return { isLoggedIn, openAuthPopup }
}

export default useOpenAuthPopup
