import type { TooltipPlacement } from 'antd/es/tooltip'
import type { FC } from 'react'

import ThemeSwitcher from '@components/ThemeSwitcher'
import RegionalMenu from '@components/header/RegionalMenu'

import TicketsButton from './components/TicketsButton'

type Props = {
  placementRegionalMenu?: TooltipPlacement
  placementTicketsMenu?: TooltipPlacement
}

const TabletDesktopButtons: FC<Props> = ({ placementRegionalMenu, placementTicketsMenu }) => {
  return (
    <>
      <ThemeSwitcher />
      <RegionalMenu placement={placementRegionalMenu} />
      <TicketsButton placementMenu={placementTicketsMenu} />
    </>
  )
}

export default TabletDesktopButtons
