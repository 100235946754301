import type { LocalesList } from '@constants/locales/localesList'

export const flagByLanguageId: Partial<Record<LocalesList, string>> = {
  en: 'us',
  /*ar: 'sa',
  el: 'gr',
  ko: 'kr',
  nb: 'no',
  sv: 'se',
  'zh-hans': 'cn',
  'zh-hant': 'tw',*/
}

export const getFlagCodeByLanguageId = (languageId: LocalesList) => flagByLanguageId[languageId] || languageId
