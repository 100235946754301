import { useRouter } from 'next/router'

export default (url: string | string[]) => {
  const router = useRouter()

  if (Array.isArray(url)) {
    return url.some(path => router.pathname.includes(path))
  }

  return router.pathname.includes(url)
}
