import dynamic from 'next/dynamic'
import type { ComponentType } from 'react'
import { type FC, useEffect } from 'react'

import { setUserCurrency } from '@redux/features/user/user.slice'
import { useAppDispatch } from '@redux/hooks'
import type { CurrencyType } from '@reduxCommonApp/types/commonAppState'

import type { RegionalMenuVariantType } from '@components/header/RegionalMenu/RegionalMenu'
import useCurrentCurrency from '@hooks/currency/useCurrentCurrency'
import useCurrentLanguageFlag from '@hooks/language/useCurrentLanguageFlag'
import { cookie } from '@services/cookies/cookies'

const ClassicView = dynamic(() => import('../ClassicView'), { ssr: false })
const ModernView = dynamic(() => import('../ModernView'))
const DefaultView = dynamic(() => import('../DefaultView'))

export type ViewPropsType = {
  currentCurrencyData?: CurrencyType
  flagCode: string
  isActive: boolean
  onClick?: () => void
}

const componentByVariant: Record<RegionalMenuVariantType, ComponentType<ViewPropsType>> = {
  classic: ClassicView,
  default: DefaultView,
  modern: ModernView,
}

type Props = {
  isActive: boolean
  onClick?: () => void
  variant: RegionalMenuVariantType
}

export const MenuContainer: FC<Props> = ({ isActive, onClick, variant }) => {
  const dispatch = useAppDispatch()
  const currentCurrencyData = useCurrentCurrency()
  const flagCode = useCurrentLanguageFlag()

  const Component = componentByVariant[variant]

  useEffect(() => {
    //TODO ONLY FOR EXPERIMENT
    const cookieCurrency = cookie.getCookie('user_currency')

    if (cookieCurrency) {
      dispatch(setUserCurrency(cookieCurrency))
    }
  }, [])

  return (
    <Component currentCurrencyData={currentCurrencyData} flagCode={flagCode} isActive={isActive} onClick={onClick} />
  )
}
